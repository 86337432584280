@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

.content {
  flex: 1;
  /* padding: 20px; */
}

.footer {
  background-color: white;
  color: black;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-left: -22px;
}

.ant-modal-footer{
  text-align: start !important;
}